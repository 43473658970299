import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '@rotaready/frecl/build/Text';

const Container = styled.div`
  flex: 1 1 auto;
  padding: 20px;
  border: solid 1px ${({ theme: { colors }, highlighted }) => highlighted ? colors.brand : colors.white};
  background-color: ${({ theme: { colors }, highlighted }) => highlighted ? colors.brand10 : colors.white};
  margin: 10px 0;
  border-radius: 6px;
  text-align: left;
  max-width: 380px;
  align-self: stretch;
`;

const IconContainer = styled.div`
  float: right;
  width: 40px;
  height: 40px;
`;

const BodyPadding = styled.div`
  margin-top: 10px;
`

const PricingPackageFeature = ({
  highlighted,
  size,
  Icon,
  titleCopy,
  bodyCopy,
}) => (
  <Container highlighted={highlighted}>
    {Icon && (
      <IconContainer>
        <Icon />
      </IconContainer>
    )}
    <Text size={size} uistyle="brand160" weight="bold">{titleCopy}</Text>
    {bodyCopy && (
      <BodyPadding>
        <Text>{bodyCopy}</Text>
      </BodyPadding>
    )}
  </Container>
);

PricingPackageFeature.propTypes = {
  highlighted: PropTypes.bool,
  size: PropTypes.string,
  Icon: PropTypes.func,
  titleCopy: PropTypes.string.isRequired,
  bodyCopy: PropTypes.string,
};

PricingPackageFeature.defaultProps = {
  highlighted: false,
  size: 'xl',
};

export default PricingPackageFeature;
